.scroll-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    background: var(--color-lightgray);
    box-shadow: 0px 0px 5px rgb(89, 85, 85);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    z-index: 999;
    cursor: pointer;
  }
  .scroll-to-top .scroll-btn {
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: arrow infinite 2s ease;
  }
  
  @keyframes arrow {
    0% {
      top: 50%;
    }
    50% {
      top: 70%;
      opacity: 0.1;
    }
    100% {
      top: 40%;
    }
  }