footer {
  background: var(--color-footer);
    color: var(--text-darkcolor);
    
  }
  footer .footer-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  footer .footer-container .footer-top {
    justify-content: space-around;
    align-items: flex-start;
    padding: 4rem;
    width: 80%;
    text-align: center;
  }
  footer .footer-container .footer-top div ul {
    margin-top: 1rem;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
  }
  footer .footer-container .footer-top div ul li:hover {
    color: var(--text-hovercolor);
    cursor: pointer;
  }
  footer .footer-container .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
    padding: 1rem;
    padding-bottom: 1rem;
  }

  @media (width <= 600px) {

    .footer-top {
      gap: 8rem;
    }

    footer .footer-container .footer-bottom  h1{
 
      font-size: 24px;
    }

  
  }