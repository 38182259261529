.section4 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

  }
  .proj{
    margin-top: -10rem;
  }
   .ProjTitle {
    font-size: 2rem;
    text-align: center;
    color: var( --text-darkcolor);
  }
  .section4 .projects-container {
    width: 100%;
    padding: 3rem;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2.5rem;
    justify-content: center;
    align-items: center;
  }
  .section4 .projects-container .project-card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-body);
    box-shadow: 0px 2px 5px rgb(45, 43, 43);
    height: 100%;
    padding: 1.5rem;

  }
 
  .section4 .projects-container .project-card .topimg img {
    margin-top: 10px;
    width: 100%;
    height: 250px;
    box-shadow: 0px 1px 4px rgb(9, 1, 1);
    border-radius: 20px;

  }

  .section4 .projects-container .project-card .buttontitle .title {
     text-align: center;
     font-size: 26px;
     font-weight: bold;
     color: var(--text-darkcolor);
     text-shadow: -1px 1px 3px rgb(118, 116, 116);
  }
  .section4 .projects-container .project-card .buttontitle .date {
    font-size: 1rem;
    color: var(--text-darkcolor);
    text-shadow: -0.5px 0.5px 1px rgb(93, 91, 91);
  }


  .section4 .projects-container .project-card .buttontitle  .description{
    font-size: 13px;
     text-align: justify;
     min-height: 120px;
    
  }

  .section4 .projects-container .project-card .buttontitle  .read{
    text-align: end;
    font-size: 14px;
    font-weight: bold;
    color: var(--text-darkcolor);
  }
  .section4 .projects-container .project-card .buttontitle p:nth-child(2) {
    margin-top: 1.2rem;
    font-weight: bold;

  }

  .sites{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .websitefrm{
    border: 1px solid black;
    padding: 5px 25px;
    border-radius: 25px;

  }
  .githubicon i{
    font-size: 2.5rem;
  }
  .textweb{
    font-weight: bold;
  }

  @media (width <= 780px) {
    .section4 {
      width: 100vw;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .section4 .projects-container {
      width: 100vw;
      justify-content: center;
      align-items: center;
     display: flex;
     flex-direction: column;
  }
  .slider{
    width: 100vw;
      
  }

 }