.section1 {
     background: var(--color-framecolor);
    grid-template-areas: "one two";
    margin: 10rem auto 3rem auto;
    width: 90vw;
    position: relative;
    padding: 0 2rem;
    box-shadow: 0px 2px 5px rgb(45, 43, 43);
   
  }
  .section1::before {
    content: " Developer";
    position: absolute;
    right: 0;
    top: -2.5rem;
    font-style: bold;
    font-size: 5rem;
    color: #132023;
    text-shadow: -1.5px 1.5px 2px #3f899b;
  }
  .section1 .left {
    grid-area: one;
    display: flex;
    justify-content: start;
    justify-items: center;
  }
  .section1 .left img {
    width: 300px;
    height: 100%;
  }
  .section1 .right {
    grid-area: two;
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
  }
  .section1 .right div .name {
    line-height: 4rem;
    font-size: 4.5rem;
    color: var(--text-colordark);
        text-shadow: -1.5px 1.5px 3px rgb(62, 60, 60);
  }
  .section1 .right div .tag {
    font-family: var(--font-montserrant);
    color: var(--text-colordark);
    text-shadow: -1.5px 1.5px 1.5px rgb(84, 83, 83);
    font-size: 2rem;
    margin-top: 1rem;
  }
  .section1 .right .button {
    margin-top: 30px;
    border: 0;
    padding: 0.8rem 1.5rem;
    border-radius: 30px;
    background: var(  --text-color-sharpblack);
    font-size: 16px;
    cursor: pointer;
   
  }
  .download{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .section1 .right .button p{
    color: var(--text-whitecolor);
  }
  .section1 .right .button i {
    color: #FFFFFF;
    margin-left: 20px;
    font-size: 1.5rem;
  }
  
  @media (width <= 768px) {
    .section1 {
      background-color: var( --color-lightgray);
      grid-template-areas: "one " "two";
      padding-bottom: 2rem;
    }
    .section1 .reight {
      align-items: center;
    }
  }
  @media (width <= 480px) {
    .section1 .left img {
      width: 100px;
    }


  }
  @media (width <= 700px) {

    .section1 .right div .name {
      font-size: 3.5rem;
    }
  }
